import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { PortableText } from "@portabletext/react";
import imageUrlBuilder from "@sanity/image-url";

import sanityClient from "../../Client";
import * as paths from "../../constants/paths";

const StyledCard = styled.article`
  background-color: var(--green50);
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.5rem;
  padding: 1.5rem;

  @media screen and (min-width: 30em) {
    grid-template-columns: 1fr 2fr;
  }

  > * + * {
    margin-top: 0.5rem;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 26rem;
  }
`;

const StyledLink = styled(Link)`
  background-color: #6e8b7c;
  display: inline-block;
  color: white;
`;

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

export const Category = ({ category }) => {
  return (
    <StyledCard>
      <img src={urlFor(category.image).url()} alt={category.category} />
      <div className="base-medium">
        <h3>
          {category.category}
        </h3>
        <PortableText value={category.intro} />
        <StyledLink
          to={paths.CATEGORY + "/" + category.slug.current}
          className="button"
        >
          View
        </StyledLink>
      </div>
    </StyledCard>
  );
};
