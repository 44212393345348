import styled from "styled-components";
import { Categories, PageTitle } from "../components";

const StyledLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(22rem, 100%), 1fr));
  grid-gap: 1rem 1.5rem;
`;

const StyledIntro = styled.p`
  font-family: var(--script-font);
  font-size: clamp(2.4rem, var(--scaler), 3rem);
  text-align: center;
  line-height: 1.1;
`;

export const Home = () => {
  return (
    <div className="base-xlarge">
      <PageTitle className="visually-hidden" title="Terra Bella Tales" />
      <StyledIntro>
        This is the story of our connection to plants, a personal journey into
        the joy, passion and fascination gardening has brought to my life and
        hopefully yours.
      </StyledIntro>

      <StyledLayout>
        <p>
          Welcome to the wonderful world of gardening! I could tell you how to
          grow a coneflower or an apple tree, but there are a thousand sources
          for that information specific to your area. My fondest hope is that my
          joy and passion for plants that surround us has an echo, that fellow
          gardeners will hear something that speaks to why they garden; why we
          spend our time, energy, and money so lavishly on cultivating plants.
        </p>
        <p>
          I am a lifetime gardener with over 40 years experience, a master
          gardener in three states, and a lifelong full time farm girl. I have
          gardened extensively in the west, South Dakota, Wyoming, and lastly,
          Montana, all zone 3 and 4 conditions and the possible plant palate
          never ceases to amaze me. I have been blessed to have lived my life
          outdoors though the consequences of the sun and wind and the long
          winters are carved upon my face-those lines tell my story.
        </p>
        <p>
          Our lives are, of course, completely entwined with the lives of
          plants. They make our world habitable and beautiful. They feed,
          clothe, shelter, and cure us. We cannot live without them. I have
          healed my heart in the gardens, I have healed my soul. May the flowers
          and the trees work their magic on you. May you find wonder. Bien
          venidos!
        </p>
      </StyledLayout>

      <Categories />
    </div>
  );
};
