import React, { useState, useEffect } from "react";
import styled from "styled-components";
import sanityClient from "../../Client";

import { Category } from "./Category";

const StyledGrid = styled.section`
  border-radius: 8px;
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(26rem, 100%), 1fr));
  gap: 1.5rem; */
`;

export const Categories = () => {
  const [categories, setCategories] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "category"]{
          _id,
          category,
          slug,
          intro,
          image{
            asset->{
              _id,
              url
            },
          },
        }`
      )
      .then((data) => {
        setCategories(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <StyledGrid className="base-xlarge">
      <h2 className="visually-hidden">All Categories</h2>
      {categories &&
        categories.map((category) => (
          <Category key={category._id} category={category} />
        ))}
    </StyledGrid>
  );
};
