import styled from "styled-components";
import { Link } from "react-router-dom";
import imageUrlBuilder from "@sanity/image-url";

import sanityClient from "../../Client";

const StyledPostContainer = styled.article`
  border-radius: 0.5rem;
  border: 1px solid var(--green100);
  position: relative;

  .blog-image {
    border-radius: 0.5rem 0.5rem 0 0;
    border: 1px solid var(--brown100);
    object-fit: cover;
    height: 12rem;
    width: 100%;
  }
`;

const StyledContentContainer = styled.div`  
  padding: 1rem 1.5rem;
`;

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

export const PostSnippet = ({ children, post, url }) => {
  return (
    <StyledPostContainer>
      {children}

      {post.mainImage && (
        <img
          src={urlFor(post.mainImage).url()}
          alt={post.title}
          className="blog-image"
        />
      )}

      <StyledContentContainer className="base-large">
        <div>
          {post.title &&<h2>{post.title}</h2>}
          <p>
            {new Intl.DateTimeFormat("en-US", {
              month: "long",
              day: "numeric",
              year: "numeric",
            }).format(new Date(post.publishedAt))}
          </p>
          {post.category && <p>Category: {post.category}</p>}
        </div>

        <Link className="button" to={url + post.slug.current}>
          Read More
        </Link>
      </StyledContentContainer>
    </StyledPostContainer>
  );
};
