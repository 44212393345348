import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledPostContainer = styled.article`
  border-radius: 0.5rem;
  border: 1px solid var(--green100);
`;

const StyledContentContainer = styled.div`
  padding: 1rem 1.5rem;
`;


export const FactoidSnippet = ({ children, post, url }) => {
  return (
    <StyledPostContainer>
      {children}
      <StyledContentContainer className="base-large">
        <div className="base-xsmall">
          <h2>{post.title}</h2>
          <p>
            {new Intl.DateTimeFormat("en-US", {
              month: "long",
              day: "numeric",
              year: "numeric",
            }).format(new Date(post.publishedAt))}
          </p>
        </div>

        <Link className="button" to={url + post.slug.current}>
          Read More
        </Link>
      </StyledContentContainer>
    </StyledPostContainer>
  );
};
