import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import imageUrlBuilder from "@sanity/image-url";

import sanityClient from "../Client";
import * as paths from "../constants/paths";
import {
  FactoidSnippet,
  PostSnippet,
  Tags,
  StyledTagContainer,
  PageTitle,
} from "../components";

const StyledGrid = styled.div`
  border-radius: 8px;
  display: grid;
  gap: 1.5rem;

  &.factoid {
    grid-template-columns: repeat(auto-fit, minmax(min(16rem, 100%), 1fr));
  }

  &.post {
    grid-template-columns: repeat(auto-fit, minmax(min(18rem, 100%), 1fr));
  }
`;

const StyledImage = styled.img`
  border-radius: 0.5rem 0.5rem 0 0;
  object-fit: cover;
  height: 8rem;
  width: 100%;
`;

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

export const Category = () => {
  const [posts, setPosts] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[categories[]->.category match $slug] | order(publishedAt desc){
          _id,
          "category": *[_type == 'category' && category match $slug][0].category,
          "subCategory": subCategories[]->subCategory,
          title,
          slug,
          publishedAt,
          images[]{asset->{_id, url}},
          mainImage{
            asset->{
              _id,
              url
            },
          },
        }`,
        { slug }
      )
      .then((data) => {
        setPosts(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [slug]);

  const category = posts && posts[0].category;

  const factoid = category === "Observations of a Plant Geek";

  console.log(posts);

  return (
    <section className="base-xlarge">
      <PageTitle title={`Category: ${category}`} />
      <StyledGrid className={factoid ? 'factoid' : 'post'}>
        {posts &&
          posts.map((post) => (
            <div key={post._id}>
              {factoid ? (
                <FactoidSnippet post={post} url={paths.FACTOID}>
                  {post.images &&
                    post.images
                      .slice(0, 1)
                      .map((img) => (
                        <StyledImage
                          src={urlFor(img.asset.url).url()}
                          alt={img.caption}
                        />
                      ))}
                </FactoidSnippet>
              ) : (
                <PostSnippet post={post} url={paths.BLOG}>
                  <StyledTagContainer>
                    {post.subCategory &&
                      post.subCategory.map((sub) => (
                        <Tags key={sub} tag={sub} />
                      ))}
                  </StyledTagContainer>
                </PostSnippet>
              )}
            </div>
          ))}
      </StyledGrid>
    </section>
  );
};
