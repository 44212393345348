import styled from 'styled-components';

const StyledFooter = styled.footer`
  background-color: var(--green100);
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  margin-top: 5rem;

  > div > p {
    font-weight: bold;
    text-align: center;
  }
`;

export const Footer = () => {
  return (
    <StyledFooter>
      <div className="terra-container">
        <p>© {new Date().getFullYear()} Terra Bella Tales</p>
      </div>
    </StyledFooter>
  );
}
