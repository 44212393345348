import styled from "styled-components";
import { NavLink } from "react-router-dom";

import * as paths from "../../constants/paths";

const StyledHeader = styled.header`
  background-color: var(--green100);
  box-shadow: 0px 8px 8px -9px rgba(0, 0, 0, 0.75);
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  margin-bottom: 3rem;

  > nav {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    

    @media screen and (min-width: 30em) {
      justify-content: space-between;
    }

    .nav-link {
      color: var(--green500);
      font-weight: bold;
      font-size: clamp(1.125rem, var(--scaler), 1.2rem);
      text-decoration: none;

      &:hover {
        color: var(--brown200);
        text-decoration: underline;
      }
    }

    .logo-link {
      color: var(--green500);
      font-weight: bold;
      font-size: clamp(2rem, var(--scaler), 2.6rem);
      text-decoration: none;
    }

    ul {
      display: flex;
      gap: 3rem;
      flex-wrap: wrap;
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
`;

export const Header = () => {
  return (
    <StyledHeader>
      <nav className="terra-container">
        <NavLink to={paths.HOME} className="logo-link">
          Terra Bella Tales
        </NavLink>

        <ul>
          <li>
            <NavLink to={paths.HOME} className="nav-link">
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to={paths.BLOG} className="nav-link">
              Blog
            </NavLink>
          </li>
        </ul>
      </nav>
    </StyledHeader>
  );
};
