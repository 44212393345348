import React, { useState, useEffect } from "react";
import styled from "styled-components";

import sanityClient from "../../Client";

const StyledFilter = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(12rem, 100%), 1fr));
  grid-gap: 0.5rem 1rem;

  > button {
    padding: 0.5rem;
  }
`;

export const Filter = ({ onClick }) => {
  const [subCategory, setSubCategory] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "subCategory"]{
          _id,
          subCategory,
        }`
      )
      .then((data) => {
        setSubCategory(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <StyledFilter>
      {subCategory &&
        subCategory.map((sub) => (
          <button key={sub._id} id={sub.subCategory} onClick={onClick}>{sub.subCategory}</button>
        ))}
          <button id="all">All</button>
    </StyledFilter>
  );
};
