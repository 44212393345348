import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  :root {
    /* Breakpoints */
    --small:  30em;
    --medium: 48em;
    --large: 60em;
    /* Standard Colors */
    --white: #FFF;
    --black: #000;
    --primary-bg: #fafaf8;
    --green50: #e2e9e7;
    --green100: #aabbb8;
    --green200: #718e89;
    --green300: #4f6360;
    --green400: #394745;
    --green500: #222a29;
    --brown100: #a4968e;
    --brown200: #394745;
    --brown300: #63564f;
    --brown400: #473e39;
    /* Text and Icons */
    --primary-text: var(--brown400);
    --secondary-text: var(--brown400);
    --font-family: 'Poppins', sans-serif;
    --script-font: "Ephesis", cursive;
    /* Focus Outline */
    --focus-outline: 2px solid var(--green200);
    /* Sizes */
    --rpx4: 0.25rem;
    --rpx8: 0.5rem;
    --rpx12: 0.75rem;
    --rpx14: 0.875rem;
    --rpx16: 1rem;
    --rpx18: 1.125rem;
    --rpx20: 1.25rem;
    --rpx22: 1.375rem;
    --rpx24: 1.5rem;
    --rpx26: 1.625rem;
    --rpx28: 1.75rem;
    --rpx30: 1.875rem;
    --rpx32: 2rem;
    --rpx34: 2.125rem;
    --rpx36: 2.25rem;
    --rpx38: 2.375rem;
    --rpx40: 2.5rem;
    /* font scaler */
    --scaler: 5vw;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
  }

  html * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    -ms-touch-action: none;
  }

  body {
    background-color: var(--primary-bg);
    font-family: var(--font-family);
    line-height: 1.5;
  }

  p, h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  
  p {
    color: var(--primary-text);
    font-size: 1rem;
  }
  p.small-body-text {
    color: $body-text;
    font-size: clamp(0.75rem, var(--scaler), 0.875rem);
  } 

  h1 {
    color: var(--brown300);
    /* min, preferred value, max */
    font-size: clamp(1.8rem, var(--scaler), 2rem);
    font-weight: 600;
  }
  h2 {
    color: var(--green400);
    font-size: clamp(1.4rem, var(--scaler), 1.5rem);
    font-weight: 400;
  }
  h3 {
    color: var(--green300);
    font-size: clamp(1.2rem, var(--scaler), 1.4rem);
    font-weight: 600;
  }
  h4 {
    color: var(--primary-text);
    font-size: clamp(1.125rem, var(--scaler), 1.2rem);
    font-weight: 600;
  }
  h5 {
    color: var(--primary-text);
    font-size: clamp(0.875rem, var(--scaler), 1rem);
    font-weight: 600;
  }
  h6 {
    color: var(--primary-text);
    font-size: clamp(0.875rem, var(--scaler), 1rem);
    font-weight: 600;
  }
  figcaption {
    color: var(--primary-text);
    font-size: clamp(0.75rem, var(--scaler), 0.875rem);
  }

  .visually-hidden {
    /* Use to hide something visually but still make it keyboard accessible */
    border: 0;
    clip: rect(0 0 0 0);
    height: auto;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
  }

  /* Base Styles */
.terra-container {
  max-width: 78rem;
  margin: 0 auto;
  padding-left: 1rem;
  padding-right: 1rem;

  @media screen and (min-width: 30em) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.base-padding {
  padding: 1.5rem 1rem;

  @media screen and (min-width: 30em) {
      padding: 2rem 1.5rem;
  }
}

.base-xsmall > * + * {
  margin-top: var(--rpx4);
}

.base-small > * + * {
  margin-top: var(--rpx8);
}

.base-medium > * + * {
  margin-top: var(--rpx16);
}

.base-large > * + * {
  margin-top: var(--rpx24);
}

.base-xlarge > * + * {
  margin-top: var(--rpx34);
}

.section-border {
  border: 1px solid var(--green50);
  width: 75%;
}

/* Buttons and Links */
a {
  color: var(--green300);
}
a:hover,
a:focus {
  color: var(--brown200);
  text-decoration: underline;
}

a:focus-visible {
  outline: var(--focus-outline);
}

a:visited {
  color: var(--green300);
}

a.button {
  background-color: var(--brown300);
  border-radius: 4px;
  color: var(--white);
  display: inline-block;
  font-family: var(--font-family);
  font-size: 1rem;
  font-weight: 400;
  min-width: 5rem;
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none;

  &:hover,
  &:focus {
    background-color: var(--green300);
    color: var(--white);
  }

  &:focus-visible {
    outline: var(--focus-outline);
  }

  &:active {
    background-color: var(--green500);
    color: var(--green50);
  }

  &.secondary {
    border: 2px solid var(--brown300);
    color: var(--white);

    &:hover,
    &:focus {
      border: 2px solid var(--brown400);
      background-color: var(--green200);
      color: var(--white);
    }

    &:focus {
      outline: var(--focus-outline);
    }

    &:active {
      color: var(--green300);
    }
  }
}

button {
  background-color: var(--brown300);
  border: 2px solid transparent;
  border-radius: 4px;
  color: var(--white);
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.08em;
  min-width: 5rem;
  padding: 0.875rem 1.5rem;

  &:hover,
  &:focus {
    background-color: var(--green400);
  }

  &:focus {
    border: 2px solid var(--green400);
    outline: none;
  }

  &.secondary {
    background-color: var(--green100);
    color: var(--black);

    &:hover,
    &:focus {
      background-color: var(--green200);
    }

    &:focus {
      border: 2px solid var(--green400);
      outline: none;
    }
  }
}

/* Forms and Inputs */
label,
legend {
  display: block;
  color: var(--primary-text);
  margin: 0.4rem 0;

  .required {
    color: #bf0000;
    font-size: 0.85rem;
  }
}
legend {
  font-size: 1.2rem;
}
input,
textarea {
  border-radius: 4px;
  border: 1px solid var(--green200);
  width: 100%;
  padding: 0.4rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-family: "Open Sans", sans-serif;

  &:focus {
    border: 2px solid var(--green400);
    outline: none;
  }
}
input {
  height: 2.4rem;
}
textarea {
  height: 5rem;
}
input[type="radio"] {
  border: 0;
  clip: rect(0 0 0 0);
  height: auto;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: auto;
  white-space: nowrap;

  /* Styled radio on checked */
  &:checked + label:before {
    background-color: var(--brown300);
    border: transparent;
    box-shadow: inset 0 0 0 2px var(--brown300), inset 0 0 0 4px var(--white);
  }

  /* edge keybord only */
  :focus:not(.focus-visible) + label:after {
    opacity: 0;
  }
  /* focus styled keyboard */
  &:focus-visible + label:after {
    opacity: 1;
  }
}
.radio-label {
  padding-left: 36px;
  position: relative;

  /* styled radio circle */
  &:before {
    background: var(--white);
    border: 2px solid var(--primary-text);
    border-radius: 50%;
    content: "";
    display: block;
    height: 1.375rem;
    left: 0;
    position: absolute;
    top: 0;
    width: 1.375rem;
  }

  /* focus ring */
  &:after {
    border-radius: 50%;
    box-shadow: 0 0 0 3px var(--green100);
    content: "";
    height: 1.375rem;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 1.375rem;
  }
}
`;

export default GlobalStyles;
