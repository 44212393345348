import React from "react";
import styled from "styled-components";

export const StyledTagContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  position: absolute;
  top: -0.75rem;
  right: 0.5rem;
`;

const StyledTag = styled.div`
  border: 1px solid var(--brown400);
  border-radius: 1rem;
  background-color: var(--green50);
  color: var(--brown400);
  font-size: clamp(0.75rem, var(--scaler), 0.875rem);
  padding: 0.125rem 0.5rem;
`;

export const Tags = ({ tag }) => {
  return (
    <StyledTag>
      {tag}
    </StyledTag>
  );
};
