import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import imageUrlBuilder from "@sanity/image-url";
import { PortableText } from "@portabletext/react";

import sanityClient from "../Client";
import { PageTitle } from "../components";

const StyledGrid = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(20rem, 100%), 1fr));
  gap: 2rem;

  img {
    border-radius: 2px;
    border: 1px solid var(--brown100);
    object-fit: contain;
    width: 100%;
  }
`;

const StyledBlogHeader = styled.div`
  border-bottom: 1px solid var(--green50);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;

`;

const StyledDetails = styled.div`
  display: flex;
  gap: 0.5rem;
  padding-bottom: 0.75rem;

  .blog-date {
    border-left: 1px solid var(--green300);
    padding-left: 0.5rem;
  }
`;

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

export const Post = () => {
  const [post, setPost] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == $slug]{
          _id,
          publishedAt,
          "subCategory": subCategories[]->subCategory,
          title,
          body,
          mainImage{
            asset->{
              _id,
              url
            },
          },
        }`,
        { slug }
      )
      .then((data) => {
        setPost(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [slug]);

  return (
    <>
      {post &&
        post.map((post) => (
          <div className="base-xlarge">
            <StyledBlogHeader>
              <PageTitle className="blog-title" title={post.title} />
              <StyledDetails>
                {post.subCategory &&
                  post.subCategory.map((sub) => <p key={sub}>{sub}</p>)}

                <p className="blog-date">
                  {new Intl.DateTimeFormat("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  }).format(new Date(post.publishedAt))}
                </p>
              </StyledDetails>
            </StyledBlogHeader>

            <StyledGrid key={post._id}>
              <div>
                {post.mainImage && (
                  <img src={urlFor(post.mainImage).url()} alt={post.title} />
                )}
              </div>
              <div className="base-large">
                <PortableText value={post.body} />
              </div>
            </StyledGrid>
          </div>
        ))}
    </>
  );
};
