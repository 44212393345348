import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";

import sanityClient from "../Client";
import { Filter, Tags, PostSnippet, StyledTagContainer, PageTitle } from "../components";
import * as paths from "../constants/paths";

const StyledGrid = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(18rem, 100%), 1fr));
  gap: 2rem;
`;

export const Blog = () => {
  // const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"] | order(publishedAt desc){
          _id,
          "category": categories[]->category,
          "subCategory": subCategories[]->subCategory,
          publishedAt,
          slug,
          title,
          mainImage{
            asset->{
              _id,
              url
            },
          },
        }`
      )
      .then((data) => {
        setPosts(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleClick = (event) => {
    setSelectedCategory(event.currentTarget.id);
  };

  const getFilteredPost = () => {
    if (!selectedCategory) {
      return posts;
    }
    return posts.filter(
      (post) =>
        post.subCategory &&
        post.subCategory.length > 0 &&
        post.subCategory.includes(selectedCategory)
    );
  };

  const filteredPosts = useMemo(getFilteredPost, [selectedCategory, posts]);

  console.log(selectedCategory)

  return (
    <div className="base-xlarge">
      <PageTitle title="Blog" />
      <Filter onClick={handleClick} />
      <StyledGrid>
        {filteredPosts &&
          filteredPosts.map((post) => (
            <PostSnippet key={post._id} post={post} url={paths.BLOG}>
              <StyledTagContainer>
                {post.subCategory &&
                  post.subCategory.map((sub) => <Tags key={sub} tag={sub} />)}
              </StyledTagContainer>
            </PostSnippet>
          ))}
      </StyledGrid>
    </div>
  );
};
