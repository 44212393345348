import styled from "styled-components";

const StyledPageTitle = styled.h1`
  color: var(--brown300);
  /* min, preferred value, max */
  font-size: clamp(1.8rem, var(--scaler), 2rem);
  font-weight: 600;

  &.blog-title {
    font-family: var(--script-font);
    font-size: clamp(2.2rem, var(--scaler), 3rem);
  }
`;

export const PageTitle = ({ className, title }) => {
  return (
    <StyledPageTitle className={className}>
      {title}
    </StyledPageTitle>
  );
};
