import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import imageUrlBuilder from "@sanity/image-url";
import { PortableText } from "@portabletext/react";

import sanityClient from "../Client";
import { PageTitle } from "../components";

const StyledGrid = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(18rem, 100%), 1fr));
  gap: 2rem;

  img {
    object-fit: contain;
    width: 100%;
  }
`;

const StyledBlogHeader = styled.div`
  border-bottom: 1px solid var(--green50);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
`;

const StyledDetails = styled.div`
  display: flex;
  gap: 0.5rem;
  padding-bottom: 0.75rem;

  .blog-date {
    border-left: 1px solid var(--green300);
    padding-left: 0.5rem;
  }
`;

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

export const Factoid = () => {
  const [factoid, setFactoid] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == $slug]{
          _id,
          "category": categories[]->category,
          publishedAt,
          images[]{caption, asset->{_id, url}},
          title,
          body,
          images,
        }`,
        { slug }
      )
      .then((data) => {
        setFactoid(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [slug]);
 
  return (
    <div className="base-large">
      {factoid &&
        factoid.map((factoid) => (
          <div key={factoid._id}>
            <StyledBlogHeader>
              <PageTitle title={factoid.title} />
              <StyledDetails>
                <p>{factoid.category}</p>

                <p className="blog-date">
                  {new Intl.DateTimeFormat("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  }).format(new Date(factoid.publishedAt))}
                </p>
              </StyledDetails>
            </StyledBlogHeader>
            <div className="base-small">
              <PortableText value={factoid.body} />
            </div>
            {factoid.images && (
              <StyledGrid>
                {factoid.images.map((img) => (
                <figure key={img._key}>
                  <img src={urlFor(img.asset._ref).url()} alt={img.caption} />
                  {img.caption && <figcaption>{img.caption}</figcaption>}
                </figure>
                ))}
              </StyledGrid>
            )}
          </div>
        ))}
    </div>
  );
};
