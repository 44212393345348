import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Footer, Header } from "./components";

import { Home, Blog, Post, Category, Factoid } from "./pages";

import * as paths from './constants/paths';
import GlobalStyles from "./styles/GlobalStyles";

function App() {
  return (
    <BrowserRouter>
      <GlobalStyles />
      <Header />
      <main className="terra-container">
        <Routes>
          <Route element={<Home />} path={paths.HOME} />
          <Route element={<Blog />} path={paths.BLOG} />
          <Route element={<Post />} path={`${paths.BLOG}:slug`} />
          <Route element={<Factoid />} path={`${paths.FACTOID}:slug`} />
          <Route
            element={<Category />}
            path={`${paths.CATEGORY}/:slug`}
          />
        </Routes>
      </main>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
